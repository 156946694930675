import logo from './logo.svg';
import './App.css';
import { useMetaMask } from "metamask-react";
// pico css
// npm install @picocss/pico
import "@picocss/pico";
import React from 'react';
// ethers
import { ethers } from "ethers";
// utils


// web3
import Web3 from "web3";
function App() {
  const wcontract = "0xdb114c4b8a030a9050874f6a6db966e0286e768f";
  const swap_addr = "0x3451775E79b6349fF5449478B89722F89F17108B";
  const backend_api = "https://api.bridge.avescoin.io";
  const linktrade = "https://pancakeswap.finance/swap?inputCurrency=0xdb114C4b8A030A9050874F6A6dB966e0286e768F&outputCurrency=0x55d398326f99059fF775485246999027B3197955"
  const { status, connect, account, chainId, ethereum } = useMetaMask();
  const [page, setPage] = React.useState("main");
  const { addChain } = useMetaMask();
  if (page == "swap_avs_avst") {
    // ensure aves network
    if (chainId != 0x8235) {
      addChain({
        chainId: "0x8235",
        chainName: "Aves Network",
        nativeCurrency: {
          name: "AVS",
          symbol: "AVS",
          decimals: 18,
        },
        rpcUrls: ["https://rpc.avescoin.io"],
        blockExplorerUrls: ["https://avescan.io"],
      });
    }
  }
  if (page == "swap_avst_avs") {
    // ensure aves network
    if (chainId != 0x38) // test net
    {
      addChain({
        chainId: "0x38",
        chainName: "Binance Smart Chain",
        nativeCurrency: {
          name: "BNB",
          symbol: "BNB",
          decimals: 18,
        },
        rpcUrls: ["https://bsc-dataseed1.binance.org/"],
        blockExplorerUrls: ["https://bscscan.com/"],
      });

    } 
    
  }

  const swap_avs_avst = (avs_amount) => {
    // check if minimum avs amount is 100
    if (avs_amount < 25) {
      alert("Minimum swap amount is 25 AVS");
      return;
    }

    // swap avs to avst
    // send avs to 0x3451775E79b6349fF5449478B89722F89F17108B, get tx hash and send to backend rpc/to_bsc/<tx_hash>
    console.log("Swapping AVS to AVST", avs_amount);
    let tx = {
      from: account,
      to: swap_addr,
      gas: "100000",
      gasPrice: "1000000000",
      value: (avs_amount * 10 ** 18).toString(16),
    };
    console.log(tx);
    ethereum.request({
      method: "eth_sendTransaction",
      params: [tx],
    }).then((tx_hash) => {
      console.log(tx_hash);
      // send tx_hash to backend
      // get url = backend_api + "/to_bsc/" + tx_hash
      fetch(backend_api + "/to_bsc/" + tx_hash, {
        // method GET
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
    
      }).then((res) => {
        console.log(res);
        if (res.status == 200) {
          alert("Swap successful");
        } else {
          alert("Swap failed");
        }
      });
    }
    );
  };
  const swap_avst_avs = (avst_amount) => {
    // check if minimum avs amount is 100
    if (avst_amount < 25) {
      alert("Minimum swap amount is 25 AVST");
      return;
    }
    const abi =[{constant:false,inputs:[{name:"_to",type:"address"},{name:"_value",type:"uint256"}],name:"transfer",outputs:[{name:"",type:"bool"}],payable:false,stateMutability:"nonpayable",type:"function"},{constant:true,inputs:[{name:"_owner",type:"address"}],name:"balanceOf",outputs:[{name:"balance",type:"uint256"}],payable:false,stateMutability:"view",type:"function"}];
    let web3 = new Web3(ethereum);
    const token = new web3.eth.Contract(abi, wcontract);
    // transnfer avst to pool_address
    token.methods.transfer(swap_addr, (ethers.parseEther(avst_amount.toString())).toString()).send({
      from: account,

    }).then((tx_hash) => {
      console.log(tx_hash);
      // send tx_hash to backend
      // get url = backend_api + "/to_aves/" + tx_hash
      fetch(backend_api + "/to_aves/" + tx_hash["transactionHash"], {
        // method GET
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
    
      }).then((res) => {
        console.log(res);
        if (res.status == 200) {
          alert("Swap successful");
        } else {
          alert("Swap failed");
        }
      });
    }
    );
  



  }

      
  return (
    <div className="App">
        <nav>
          <ul>
            <li><strong>Aves Swap</strong></li>
          </ul>

          <ul>
            {
              page !== "main" ? (
                <li><a href="#" role="button" onClick={() => {
                  setPage("main");
                }
                }>Back</a></li>
              ) : null
                  
            }
            <li><a href={linktrade} target="_blank">Trade AVST on PancakeSwap 
            </a></li>
            <li><a href="#" role="button"
              onClick={() => {
                if (status === "connected") {
                  alert("Please use wallet to disconnect");
                } else {
                  console.log("Connecting...");
                  connect();
                }
              }}
            >
              {status === "connected" ? "Disconnect" : "Connect"}

            </a></li>
          </ul>
        </nav>
      <header className="App-header">

        {
          page === "main" ? (
            <div>
              <p>
                Welcome to Aves Swap
              </p>
              <div className="grid">
                <article>
                    <header>AVS to AVST</header>
                    <p>
                      You are SWAPPING <strong>AVS</strong> to <strong>AVST</strong>
                    </p>
                    <p> Aves Coin on Aves Network to Aves Token (AVST) on BSC Network</p>
                    {
                      status === "connected" ? (
                        <button
                          onClick={() => {
                            setPage("swap_avs_avst");
                          }}
                        >
                          Swap
                        </button>
                      ) : <button disabled>Connect to swap</button>
                    }
                  </article>
                <article>
                    <header>AVST to AVS</header>
                    <p>
                      You are SWAPPING <strong>AVST</strong> to <strong>AVS</strong>
                    </p>
                    <p> Aves Token (AVST) on BSC Network to Aves Coin on Aves Network</p>
                    {
                      status === "connected" ? (
                        <button
                          onClick={() => {
                            setPage("swap_avst_avs");
                          }}
                        >
                          Swap
                        </button>
                      ) :  <button disabled>Connect to swap</button>
                    }
                  </article>
              </div>
                
            </div>
          ) : null
        }
        {
        page === "swap_avs_avst" ? (
          chainId != 0x8235 ? (
            <p>Switch to Aves Network</p>
          ) : (
            <div className="grid">
              <article>
                <header>AVS to AVST</header>
                <p>YOU ARE SWAPPING AVES COIN to AVES TOKEN</p>
                <p>Tokens are recived on BSC Network on connected wallet</p>
              </article>
              <article>
                <header>Swap</header>
                <p> Minimum 25 AVS, Fee 20 AVS</p>
                <input type="text" placeholder="0.0" id="avs_amount" />
                <button
                  onClick={() => {
                    let avs = document.getElementById("avs_amount").value;
                    if (avs == "") {
                      alert("Enter amount");
                      return;
                    }
                    if (isNaN(avs)) {
                      alert("Enter valid amount");
                      return;
                    }
                    swap_avs_avst(avs.toString());
                  }}


                >Swap</button>
              </article>
            </div>
          )
          
          ) : null
        }
        {
          page === "swap_avst_avs" ? (
            chainId == 0x38 ? (
            
              <div className="grid">
                <article>
                  <header>AVST to AVS</header>
                  <p>YOU ARE SWAPPING AVES TOKEN to AVES COIN</p>
                  <p>Tokens are recived on Aves Network on connected wallet</p>
                  <footer>
                  <button onClick={() => {
                    // add token
                    ethereum.request({
                      method: "wallet_watchAsset",
                      params: {
                        type: "ERC20",
                        options: {
                          // 0x1ca1eb17c0a48eb3a4d2f1b2ee7ca05f3e369b4d
                          address: "0xdb114c4b8a030a9050874f6a6db966e0286e768f",
                          symbol: "AVST",
                          decimals: 18,
                          image: "https://aves.network/aves.png",
                        },
                      },
                    }).then((success) => {
                      if (success) {
                        console.log("Successfully added AVST");
                      } else {
                        console.log("Something went wrong.");
                      }
                    });
                  }}>Add AVST to wallet</button>
                  </footer>
                </article>
                <article>
                  <header>Swap</header>
                  <p> Minimum 25 AVST, Fee 20 AVST</p>
                  <input type="text" placeholder="0.0" id="avst_amount" />
                  <button
                    onClick={() => {
                      let avst = document.getElementById("avst_amount").value;
                      if (avst == "") {
                        alert("Enter amount");
                        return;
                      }
                      if (isNaN(avst)) {
                        alert("Enter valid amount");
                        return;
                      }
                      swap_avst_avs(avst.toString());
                    }}
                  >Swap</button>
                </article>
              </div>
            ) : (
              <p>Switch to BSC Network</p>
            )
          ) : null
        }

        

      </header>
    </div>
  );

}

export default App;
